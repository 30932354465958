<template>
  <div>
    <div>
      <h1>We'd love to hear from you!</h1>
      <p>
        Our goal is to encourage everyone to live an active and healthy lifestyle. 
        Studies show having healthy employees improves productivity and radically reduces healthcare costs.
      </p>
      <p>
        <v-btn outlined color="primary" to="/about">More about us</v-btn>
      </p>


      <v-row class="mb-8 mx-0 align-center">
        <v-col cols="12" class="mb-0 pb-0">
          <!-- <h3 class="mb-0 pb-0">My Question Is About?</h3> -->
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="mode=='contact'?'primary lighten-2':''" 
            icon="fal fa-info-circle"
            icon-color="black"
            title="Contact" 
            text="For general inquiries and information on our platform." 
            @click="mode='contact'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="mode=='demo'?'primary lighten-2':''" 
            icon="fal fa-user-headset"
            icon-color="black"
            title="Demo or Call" 
            text="Schedule a free 15-30 minute demo or call." 
            @click="mode='demo'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="mode=='support'?'primary lighten-2':''" 
            icon="fal fa-life-ring"
            icon-color="black"
            title="Support" 
            text="For users participating in an event on our platform." 
            @click="mode='support'"/>
        </v-col>
      </v-row>

  <!--     <script type="application/javascript" src='https://noctilucentbv.myfreshworks.com/crm/sales/web_forms/096908605eb5eabd275b43e48d9229ebeb4689f9552bb3176aae585aeea70943/form.js' crossorigin='anonymous' id='fs_096908605eb5eabd275b43e48d9229ebeb4689f9552bb3176aae585aeea70943'></script> -->

  <!-- zoho

      <v-form lazy-validation ref="form" name=WebToLeads311694000000341011 action="https://crm.zoho.eu/crm/WebToLeadForm" method="POST">
        <input type='hidden' name='xnQsjsdp' value='529d92437bb2ad17c3fe95805d769379b5d1d7b2d99a331c2cea4fed8dd78579'/>
        <input type='hidden' name='zc_gad' id='zc_gad' value=''/>
        <input type='hidden' name='xmIwtLD' value='d3792969e786d2a3c7b3a3f42e79e9077850c59d203cb42d05914a0b5bf86f0a'/>
        <input type='hidden' name='actionType' value='TGVhZHM='/>
        <input type='hidden' name='returnURL' value='https://sodisp.com/thankyou' />

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          id="Last_Name"
          name="Last Name"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="Email"
          id="Email"
          type="Email"
          required
        ></v-text-field>

        <v-textarea v-model="comments" required label="Your message:" name="Description" id="Description"></v-textarea>
      
        <v-btn class="primary" type="submit">Submit</v-btn>
      </v-form> 

      <hr/>
  -->

    <div v-if="mode=='contact'">
      <h3>Contact Us</h3>
      <p><v-icon small class="me-1">fa-question-circle</v-icon> Looking for support? Please <a @click="mode='support'">use the support form</a> so we can help you faster.</p>
      <p>Leave a message using the form below, or simply email us at <a :href="`mailto:${tenant.email}`">{{tenant.email}}</a>.</p>

      <v-form lazy-validation ref="contactForm" action="https://formspree.io/f/mpzknpqw" method="POST">

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          name="name"
          required
        ></v-text-field>

      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization Name"
        name="organization"
        required
      ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="email"
          type="email"
          required
        ></v-text-field>

      <v-text-field
        v-model="phone"
        :rules="phoneRules"
        label="Phone number"
        name="phone"
        type="tel"
        required
      ></v-text-field>

        <v-textarea v-model="comments" required label="Your message:" name="comments"></v-textarea>
        <input type="hidden" name="timezone" :value="timeZoneOlson"/>
        <input type="hidden" name="mode" value="Contact Form"/>
        <input type="hidden" name="tenant" :value="tenant.name"/>
      
        <v-btn class="primary gtm-track-click" x-large @click="submit('contact', $refs.contactForm)">Submit</v-btn>
      </v-form> 
    </div>

    <div v-if="mode=='demo'">
      <div v-if="true || now < DateTime.utc(2024, 6, 1)">
        <h3>Book Directly</h3>
        <p>Book a 30-minute demo on the day and time that works best for you.</p>
        <p>
          <v-btn class="gtm-click-demo" color="accent" large href="https://corporatefitness.app/r/go/book-demo" target="_blank">Book now</v-btn>
          <!-- <v-btn color="accent" large href="https://noctilucent.pipedrive.com/scheduler/qOPkedim/demo-request" target="_blank">Book now</v-btn> -->
        </p>
      </div>

      <h3>Request a demo or call</h3>
      <p>Looking for a demo on a different time slot or have additional questions? Leave a message using the form below, or simply email us at <a :href="`mailto:${tenant.email}`">{{tenant.email}}</a>.</p>


      <v-form lazy-validation ref="demoForm" action="https://formspree.io/f/mpzknpqw" method="POST">

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          name="name"
          required
        ></v-text-field>

      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization Name"
        name="organization"
        required
      ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="email"
          type="email"
          required
        ></v-text-field>

      <v-text-field
        v-model="phone"
        :rules="phoneRules"
        label="Phone number"
        name="phone"
        type="tel"
        required
      ></v-text-field>

      <v-text-field v-model="meetingDate" label="Date and time preference" name="when" hint="When would you prefer to have this demo? Please include your time zone." />

      <v-textarea v-model="comments" class="mb-8" label="Specific topics, features or comments:" persistent-hint hint="Let us know if you have specific questions or features you'd like to include in the demo." name="comments"></v-textarea>

        <input type="hidden" name="timezone" :value="timeZoneOlson"/>
        <input type="hidden" name="mode" value="Demo/Call Form"/>
        <input type="hidden" name="tenant" :value="tenant.name"/>
      
        <v-btn class="primary gtm-track-click" x-large @click="submit('demo', $refs.demoForm)">Submit</v-btn>
      </v-form> 
    </div>
    <div v-if="mode=='support'">
      <h3>Support Question</h3>
      <p><strong>Please email directly to the organization hosting your event for faster response times!</strong></p>
      <p>Alternatively, leave a message using form below and we'll get back to you. Please make sure to enter all fields so we can help you more efficiently.</p>

      <v-form lazy-validation ref="supportForm" action="https://formspree.io/f/xgeqqwlq" method="POST">

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          name="name"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="email"
          type="email"
          required
        ></v-text-field>

      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization / challenge name"
        name="organization"
        required
      ></v-text-field>

      <v-text-field
        v-model="profileId"
        label="Your profile ID"
        name="profileID"
        hint="Please paste your profile ID. You can find this on the Edit Profile page."
        persistent-hint
      ></v-text-field>

        <v-textarea v-model="comments" required label="Your question:" name="comments" persistent-hint hint="Please include as many details as possible."></v-textarea>
        
        <input type="hidden" name="timezone" :value="timeZoneOlson"/>
        <input type="hidden" name="mode" value="Support Form"/>
        <input type="hidden" name="tenant" :value="tenant.name"/>

        <v-btn class="primary" type="submit" x-large>Submit</v-btn>
      </v-form> 
    </div>

      <h3 class="mt-8">Trusted by over {{ siteData.stats.total_orgs | int }} organizations world-wide</h3>
      <v-slide-group show-arrows v-model="currentSlideIndex">
        <v-slide-item
          v-for="(item, idx) in siteData.customers.filter(x => x.corporate)"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <a :href="item.url" target="_blank">
          <v-sheet :elevation="1" @click="toggle" :input-value="active" class="text-center pa-2 ma-2 d-flex" height="60" color="transparent" :title="item.name" style="background-color: rgba(255,255,255,0.1) !important; line-height:85px;">
            <img :src="`https://sodisp.imgix.net/web/customers/${item.id}.png?h=50&w=100`" :alt="item.name" class="d-inline-block" :style="`align-self: center; max-width:100px; max-height:50px;`"/>
          </v-sheet>
          </a>
        </v-slide-item>
      </v-slide-group>      
      </div>
  </div>
</template>


<script>
import siteData from '@/data/site.json'
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import { EventBus } from '@/plugins/eventbus.js';
import BigButton from "@/components/BigButton";
import platformService from "@/services/platformService";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    BigButton,
  },
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      siteData: siteData,
      now: DateTime.now(),
      DateTime: DateTime,
      currentSlideIndex: 0,
      interval: 0,
      valid: true,
      mode: 'contact',
      timeZoneOlson: null,
      org: '',
      phone: '',
      profileId: '',
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      orgRules: [
        v => !!v || 'Please enter the name of your organization',
      ],
      phoneRules: [
        v => !!v || 'Please enter a phone number where we can reach you',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      comments: '',
      meetingDate: '',
    };
  },
  created() {
    var maxIndex = siteData.customers.filter(x => x.corporate).length;
    this.interval = setInterval(() => this.currentSlideIndex > maxIndex ? this.currentSlideIndex = 0 : this.currentSlideIndex++, 1000);
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  async mounted() {
    //this.track();
    this.mode = this.$route.query.mode || this.mode;
    this.name = this.$route.query.name;
    this.email = this.$route.query.email;
    this.comments = this.$route.query.comments;
    this.timeZoneOlson = DateTime.now().zoneName;


    if (this.email) {
      //console.log('Scrolling to form');
      await this.$nextTick();
      this.$refs.form.$el.scrollIntoView();
    }
    console.log('this.user', this.user);
    if (this.user) {
      this.loadProfile();
    }
    EventBus.$on('login-state-change', async user => {
      if (user) {
        this.loadProfile();
      }
    });

    console.log('// analytics', this.$analytics.synced);
  },
  methods: {
    async submit(formName, form) {
      
      //console.log('Form submission.', form);
      if (form && form.validate()) {
        try {
          this.$analytics.event('contact_submit', { 'event_category': 'contact', 'event_label': formName });
          this.$analytics.event('generate_lead', {
            currency: 'USD',
            value: 0, // unknown value
          });

          let request = {
            tenant_id: tenant.id,
            name: this.name,
            org: this.org,
            email: this.email,
            phone: this.phone,
            comments: this.comments,
            time_zone: this.timeZoneOlson,
            meeting_date: this.meetingDate,
          };
          let response = await platformService.postForm(formName, request);
          if (response && response.data) {
            this.formResult = response.data;
          }
        }
        catch {}

        form.$el.submit();
      }
    },
    async loadProfile() {
      var response = await profileService.get();
      this.profileId = response.data.id;
      if (!response.data.random_name) {
        this.name = response.data.name;
      }
      this.email = response.data.email;
    },
    track () {

      //console.log('Tracking event', this.$analytics, 'includes', window.enableAnalyticsProperty);
      //window.disableAnalyticsProperty('UA-163164505-2');
      //window.enableAnalyticsProperty('UA-163164505-2');
      //this.$analytics.query('config', 'UA-163164505-2', {'send_page_view': false});
      //this.$analytics.enableCustomTrackers(this, [ 'UA-163164505-2' ]);
      //this.$analytics.disableCustomTrackers(this);
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped>
</style>